// export const APIURL = process.env.REACT_APP_API
// export const DISPENSARY_TOKEN = process.env.DISPENSARY_TOKEN

export const APIConfig = {
  APIURL: 'https://practice-api.webgarh.net/formula-api',
  //APIURL: 'http://localhost/practice-api/formula-api',
  DISPENSARY_TOKEN: '46e3c95a8b75e2503c',
  PRAC_ID: '2',
  PATIENT_ADD_FORM_URL:
    'https://herbdispenser.vxy.in/?page=patient&section=add&',
  // PATIENT_ADD_FORM_URL:
  //   'http://localhost/multidispensary/user/?page=patient&section=add&',
  BASEURL: 'https://herbdispenser.vxy.in/',
  //BASEURL: 'http://localhost/multidispensary/user/',
  IFRAMEURL: 'https://herbdispenser.vxy.in/?page=details&',
}

export const APIHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': true,
  'Accept-Encoding': 'gzip',
}
